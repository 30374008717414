import React from "react";
import { Link } from "react-router-dom";
import LeftArrow from "../../assets/images/arrow-left.png";
import RightArrow from "../../assets/images/arrow-right.png";

const SectionBanner = (props) => {
    
  return (
    <section className="section section__banner d-flex">
      <div className="container">
        <div className="slideshow__container">
          <div className="banner__slide">
            <div className="row flex-row-reverse justify-content-center align-items-center h-100">
              <div className="col-md-6 col-sm-12">
                <img
                  src={props.banner[props.currentSlider].img}
                  alt="Education Management Software"
                />
              </div>
              <div className="col-md-6 col-sm-12 homeBanner__text">
                <h1>{props.banner[props.currentSlider].h1}</h1>
                <h5>{props.banner[props.currentSlider].h5}</h5>
                <h4>
                  <span>{props.banner[props.currentSlider].span}</span>
                  <br />
                  {props.banner[props.currentSlider].h4}
                </h4>
                <div className={"button__display--" + props.banner[props.currentSlider].buttonDisplay}>
                <Link to={props.banner[props.currentSlider].buttonLink}>
                  <button className="button button--banner">
                    {props.banner[props.currentSlider].buttonText}
                  </button>
                </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="arrow__container">
            <div className="arrow__prev" onClick={props.prev}>
              <img src={LeftArrow} alt="Sealtabs" />
            </div>
            <div className="arrow__next" onClick={props.next}>
              <img src={RightArrow} alt="Sealtabs" />
            </div>
          </div>
          <div className="dot__container">
            {props.banner.map((ban, index) => {
              return props.currentSlider === index ? (
                <span
                  className="dot banner__slide--active"
                  onClick={() => props.carouselHandler(index)}
                ></span>
              ) : (
                <span
                  className="dot"
                  onClick={() => props.carouselHandler(index)}
                ></span>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionBanner;
