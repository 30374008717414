import React from "react";
import LeftArrow from "../../../assets/images/arrow-left.png";
import RightArrow from "../../../assets/images/arrow-right.png";
import Star from "../../../assets/images/star.png";
import QuoteLeft from "../../../assets/images/quote-left.png";
import QuoteRight from "../../../assets/images/quote-right.png";

const SectionTestimonials = (props) => {
  return (
    <section className="section section__testimonials">
      <div className="testimonials__container">
        <div className="container">
          <h4 className="text-center">Testimonials</h4>
          <h3 className="text-center whySealtabsTestimonials">What clients are saying</h3>
          <div className="testimonials__wrapper">
            <img
              className="testimonials__quoteleft"
              src={QuoteLeft}
              alt="Quotes"
            />
            <img
              className="testimonials__quoteright"
              src={QuoteRight}
              alt="Quotes"
            />
            <div className="arrow__container">
              <div className="arrow__prev" onClick={props.prev}>
                <img src={LeftArrow} alt="Sealtabs" />
              </div>
              <div className="arrow__next" onClick={props.next}>
                <img src={RightArrow} alt="Sealtabs" />
              </div>
            </div>

            <div className="testimonials__wrap">
              <div>
              <p>"{props.testimonials[props.currentTestimonial].content}"</p>
              <div className="row">
                <div className="col-md-8">
                  <h4>
                    {props.testimonials[props.currentTestimonial].name}
                    <span className="testimonials--designation">
                      &nbsp;
                      {props.testimonials[props.currentTestimonial].designation}
                    </span>
                  </h4>

                  <p className="testimonials--school">
                    {props.testimonials[props.currentTestimonial].school}
                  </p>
                  <div className="start__container">
                    <img src={Star} alt="star" />
                    <img src={Star} alt="star" />
                    <img src={Star} alt="star" />
                    <img src={Star} alt="star" />
                    <img src={Star} alt="star" />
                  </div>
                </div>
                <div className="col-md-4">
                  <img
                    className="school__logo"
                    src={props.testimonials[props.currentTestimonial].logo}
                    alt={props.testimonials[props.currentTestimonial].school}
                  />
                </div>
              </div>
              </div>
              <div className="clear"></div>
            </div>
            <div className="dot__container">
              {props.testimonials.map((banner, slide) => {
                return props.currentTestimonial === slide ? (
                  <span
                    className="dot banner__slide--active"
                    onClick={() => props.testimonialHandler(slide)}
                  ></span>
                ) : (
                  <span
                    className="dot"
                    onClick={() => props.testimonialHandler(slide)}
                  ></span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionTestimonials;
