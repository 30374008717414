import React from "react";
import ContactUsBanner from "./contactUsBanner";
import ContactDetails from "./contactDetails";
import Ourlocations from "./ourlocations";

const ContactUs = (props) => {
  return (
    <div className="contactus">
      <ContactUsBanner />
      <ContactDetails />
      <Ourlocations />
      <div className="clear"></div>
    </div>
  );
};

export default ContactUs;
