import React from "react";

const Ourlocations = props => {
  return (
    <section className="section section__ourlocations">
    <div className="container">
      <h4>Our Locations</h4>
      <div className="row">
        <div className="col-md-4">
            <h5>Bangalore</h5>
            <address>#4, Patel Ram Reddy Rd,<br/> Krishna Reddy Layout, Domlur<br/> 1st Stage, Bangalore</address>
        </div>

        <div className="col-md-4">
            <h5>Chennai</h5>
            <address>G3, Ground Floor, Plot No 33,<br/> Alwar Nagar 3rd Street,<br/> Nanganallur, Chennai</address>
        </div>

        <div className="col-md-4">
            <h5>Hyderabad</h5>
            <address>No 26-95/4/3, BN 57,<br/> Balram Nagar, Road No 1,<br/> Safilguda, Hyderabad</address>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
            <h5>Jamshedpur</h5>
            <address>No 1B, Eiphel Tower,<br/> Road no 3, Contractors Area,<br/> Bistupur, Jamshedpur</address>
        </div>

        <div className="col-md-4">
            <h5>America &amp; Latin America</h5>
            <address>110 River Drive, 2606 Jersey City,<br/> New Jersey</address>
        </div>

        <div className="col-md-4">
            <h5>United Kingdom &amp; Europe</h5>
            <address>#25,Lucas Road, Glais, SA7 <br/>9EU, United Kingdom</address>
        </div>
      </div>
    </div>
    <div className="clear"></div>
  </section>
  );
};

export default Ourlocations;
