import React from "react";
import Adaptability from "../../assets/images/adaptability.jpg";

const WhySealtabsDataRetrieval = (props) => {
  return (
    <section className="section section__dataRetrieval">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={Adaptability}
              alt="Adaptability"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Adaptability</h3>
                <p>
                SEALTABS best known for versatility; it is accustomed to the structure of the institute rather than asking the institute to adapt with us. To provide user-friendly software our team designs according to the institution's requirements.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsDataRetrieval;
