import React from "react";
import { Link } from "react-router-dom";

const NotMatch = (props) => {
  return (
    <div className="pageNotFound">
      <div className="height--100vh display--center">
        <div className="container">
          <h1>404 Page not found!</h1>
          <Link to="/">
            <button className="button">Back to home</button>
          </Link>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default NotMatch;
