import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="sitemap">
        <section className="privacyPolicy section section__banner d-flex">
          <div className="container">
            <h1>Privacy policy</h1>
            <p>
            We value your trust. In order to honour that trust, SEALTABS adheres to ethical standards in
gathering, using, and safeguarding any information you provide.
            </p>
            <p>
            This privacy policy governs your use of the application ‘SEALTABS’s’ ('<b>Application</b>'),
            <a target="_blank" rel="noreferrer" href="https://www.sealtabs.com"> www.sealtabs.com</a> ('<b>Website</b>') and the other associated applications, products, websites and
services managed by the Company.
            </p>
            <p>Please read this privacy policy ('<b>Policy</b>') carefully before using the Application, Website, our
services and products, along with the Terms of Use ('<b>ToU</b>') provided on the Application and
the Website. Your use of the Website, Application, or services in connection with the
Application, Website or products ('<b>Services</b>'), or registrations with us through any modes or
usage of any products including through SD cards, tablets or other storage/transmitting
device shall signify your acceptance of this Policy and your agreement to be legally bound by
the same.</p>
<p>If you do not agree with the terms of this Policy, do not use the Website, Application our
products or avail any of our Services.</p>
            <h4 className="termsSubhead">A. GENERAL</h4>
            <p>In this policy, the words "we", "our", and "us" refer to SEALTABS and the privacy mechanism
outlined identifies personally identifiable or personal information that may be collected, how
such information is used, and the choices you have regarding our use of this information.</p>
<p>In addition to the terms stated in Terms of Service provided under this Website/mobile
application (together “Platform”), we are committed to protecting your privacy. Authorized
representatives of SEALTABS on a need to know basis only use any information received from
you. We constantly review our systems and data to secure your personal information. We will
investigate any complaint or such actions with a view to prosecuting and/or taking civil
proceedings to recover damages against those responsible. Any User who does not agree with
any provisions of this Privacy Policy and the Terms of Service is required to leave the Platform
immediately. Should you disagree with this Policy (defined below) and still continue to access
the Platform and provide your personal information, SEALTABS disclaim all the liabilities
arising therefrom and it shall be assumed by your continued use of the Platform that you have
accepted the Policy and any other online terms and conditions available on the Platform.</p>
            

<h4 className="termsSubhead">B. USER PROVIDED INFORMATION</h4>
<p>The Application/Website/Services/products obtains the information you provide when you
download and register for the Application or Services or products. When you register with us,
you generally provide (a) your name, age, email address, location, phone number, and
password; (b) information you provide us when you contact us for help; (c) information you
enter into our system when using the Application/Services/products, such as while asking doubts, participating in discussions and taking tests. The said information collected from the
users could be categorized as “Personal Information”, “Sensitive Personal Information” and
“Associated Information”. Personal Information, Sensitive Personal Information and
Associated Information (each as individually defined under this Information Technology
(Reasonable security practices and procedures and sensitive personal data or information)
Rules, 2011 (the “Data Protection Rules”)) shall collectively be referred to as 'Information' in
this Policy.</p>
<p>We may use the Information to contact you from time to time, to provide you with the
Services, important information, required notices and marketing promotions. We will ask you
when we need more information that personally identifies you (personal information) or
allows us to contact you.</p>
<p>We will not differentiate between who is using the device to access the Application, Website
or Services or products, so long as the log in/access credentials match with yours. In order to
make the best use of the Application/Website/Services/products and enable your Information
to be captured accurately on the Application/Website/Services/products, it is essential that
you have logged in using your own credentials.</p>
<p>We will, at all times, provide the option to you to not provide the Personal Information or
Sensitive Personal Information, which we seek from you. Further, you shall, at any time while
using the Application/Services/products, also have an option to withdraw your consent given
earlier to us to use such Personal Information or Sensitive Personal Information. Such
withdrawal of the consent is required to be sent in writing to us at the contact details
provided in this Policy below. In such an event, however, the Company fully reserves the right
not to allow further usage of the Application or provide any Services/products thereunder to
you.</p>
<h4 className="termsSubhead">C. AUTOMATIC INFORMATION COLLECTION</h4>
<p><b>Our system collects information that is sent over the internet without your control.</b> Some
examples of the information we collect and analyze include the Internet protocol (IP) address
used to connect your computer to the internet; login; email; password; computer and
connection information such as browser type and version, operating system, and platform;
Class/Course enrolled history, which we sometimes aggregate with similar information from
other users to create features, reports such as reports/courses, most used features etc; the
full Uniform Resource Locator (URL) click stream to, and from our Platform, including date
and time; cookie number; courses or videos you viewed or searched for; the email id you used
to call our customer service.</p>
<p>During some visits we may use software tools such as JavaScript to measure and collect
session information, including page response times, download errors, length of visits to
certain page, course and videos, page interaction information (such as scrolling, clicks and
mouse overs), and methods used to browse away from the page.</p>
<h4 className="termsSubhead">D. INFORMATION SECURITY</h4>
<p>To protect the security of your Personal information during transmission, we use Secure
Sockets Layer (SSL) software, which encrypts information you input. To prevent unauthorized
access to sensitive information, it is important for you to monitor those who have access to
your password and to your computer, laptop or any other device through which your account
can be accessed. Be sure to sign off when finished while using a shared computer</p>
<h4 className="termsSubhead">E. USE OF YOUR PERSONAL INFORMATION</h4>
<p>While availing our services you will provide access to SEALTABS with your Personal
Information. If that information is wrong, you can request us to modify or delete the same.
SEALTABS will take all reasonable measures to ensure that the Personal Information is
modified and used for rendering services to you and as otherwise in compliance with laws.
When editing or deleting Personal Information, we may ask you to verify your identity before
we can act on your request. SEALTABS may at its discretion reject any request that is contrary
to law, requiring un-reasonable technical efforts. We do not assure that we will delete all
residual copies and archives made by any third party without our knowledge and consent.</p>
<p>SEALTABS shall use your Personal Information to communicate with you. The said
communication can either be by calls, text or emails. If at any time you wish to not receive
any communication from our end you can opt-out of the same by writing to us on 
<a href="mailto:info@nissiagency.com"> info@nissiagency.com</a>.</p>
<h4 className="termsSubhead">F. EXCEPTIONS</h4>
<p>Your Personal Information may be shared with third parties who have a need or authority to
receive such information, if we have a good-faith belief that access, use, preservation or
disclosure of the information is reasonably necessary to comply with (i) in response to any
authority having to receive such information under law (ii) any order of court (iii) detect,
prevent, or otherwise address fraud, security or technical issues (iv) protect against harm to
the rights, property or safety of SEALTABS, our users or the public as required or permitted by
law.</p>
<h4 className="termsSubhead">G. APPLICABILITY</h4>
<p>This privacy policy (“Policy”) applies to all Users who access the Platform and are therefore
required to read and understand the Policy before submitting any Personal Information
(defined hereinafter). If you have inadvertently submitted any Personal Information to
SEALTABS prior to reading the Policy statements set out herein, and you do not agree with the
manner in which the Personal Information is collected, stored or used, then you may access,
modify and delete all information stored about You by writing to us at <a href="mailto:info@nissiagency.com"> info@nissiagency.com</a>.
SEALTABS is keeping personal data of the User, and every User is also entitled to require
SEALTABS to delete and destroy all such information (but not that of other Users).
This Policy applies to the Platform and any other services that are owned and operated by
SEALTABS. Third party websites may place their own cookies or other files on the Users'
computer, collect data or solicit personal information from the Users, for which SEALTABS is not responsible or liable. Accordingly, SEALTABS does not make any representations
concerning the privacy practices or policies of such third parties or terms of use of such
websites, nor does SEALTABS guarantee the accuracy, integrity, or quality of the information,
data, text, software, sound, photographs, graphics, videos, messages or other materials
available on such websites. The inclusion or exclusion does not imply any endorsement by
SEALTABS of the website, the website's provider, or the information on the website. SEALTABS
encourages the User to read the privacy policies of that website.</p>
<p>SEALTABS has taken all reasonable precautions to treat Personal Information as confidential
with industry standards that it has implemented to protect from unauthorized access,
improper use or disclosure, modification and unlawful destruction or accidental loss of the
Personal Information.</p>
<h4 className="termsSubhead">H. PERSONAL INFORMATION</h4>
<p>“Personal Information” shall mean the information which identifies the User, name,
identification number, email address, age, phone number, password or any financial account
information provided to SEALTABS at the time of registration or any time thereafter.</p>
<h4 className="termsSubhead">I. INFORMATION SECURITY</h4>
<p>To protect the security of your Personal information during transmission, we use Secure
Sockets Layer (SSL) software, which encrypts information you input. To prevent unauthorized
access to sensitive information, it is important for you to monitor those who have access to
your password and to your computer, laptop or any other device through which your account
can be accessed. Be sure to sign off when finished while using a shared computer.</p>
<h4 className="termsSubhead">USER CHOICES WITH REGARD TO ACCESS OF INFORMATION</h4>
<p>SEALTABS does not make any unsolicited calls or otherwise market any products or services,
except for in relation to the purpose for which such information has been provided or taking
any feedback, addressing any complaints, informing you about new features or free/paid
courses. Users shall not disclose their Personal Information to any third parties that are not
authorized by SEALTABS and verify the identity of such person who seek information.
SEALTABS will communicate with the Users through call, SMS, email or notices posted on the
Platform or through any other means available, which shall include but not be limited to text,
other forms of messaging, calls etc. The Users can change their email and contact
preferences at any time by logging into their account or by emailing at <a href="mailto:info@nissiagency.com"> info@nissiagency.com</a>.</p>
<p>SEALTABS may, if you so choose, send direct mailers to you at the address given by you. You
have the option to 'opt-out' of this direct mailer by way of links provided at the bottom of
each mailer. We respect your privacy and to the extent that you choose not to receive such
mailers, we will take all steps to remove you from the list. Should you evidence any violation
of our terms and policies, please write to us at <a href="mailto:info@nissiagency.com"> info@nissiagency.com</a> and report such
incidents.</p>
<p>All the information provided to SEALTABS by you, you as a User confirms that the same
including sensitive Personal Information, is true, accurate and voluntary. Users have the right
to withdraw information at any time, in accordance with the terms of this Policy and the
Terms of Service.</p>
<h4 className="termsSubhead">SHARING OF INFORMATION</h4>
<p>SEALTABS may also disclose or transfer User's personal and other information which a User
provides, to another third party as part of reorganization or a sale of the assets of SEALTABS
corporation division or company. Any third party to which SEALTABS transfers or sells its
assets will have the right to continue to use the personal and other information that a User
provides to us.</p>
<p>To the extent necessary to provide Users with the services on the Platform, SEALTABS may
provide User’s Personal Information to third party contractors who work on behalf of or with
SEALTABS to provide Users with such services, to help SEALTABS communicate with Users or
to maintain the Platform. Generally, these contractors do not have any independent right to
share this information, however certain contractors who provide services on the Platform,
including the providers of online communications services, online payment gateway services,
will have rights to use and disclose the Personal Information collected in connection with the
provision of these services in accordance with their own privacy policies.</p>
<h4 className="termsSubhead">REPORT, ANALYSIS &amp; CONFIDENTIALITY</h4>
<p>SEALTABS may have to use Personal Information without reference to any persona or identity
for research, statistical analysis and business intelligence purposes and may transfer such
research, statistical or intelligence data in an aggregated or non-personally identifiable form
to third parties and affiliates.</p>
<p>All SEALTABS employees and data processors, who have access to, and are associated with the
processing of sensitive personal data or information, are obliged to respect the confidentiality
of every User's sensitive personal data and information.</p>
<h4 className="termsSubhead">REVISION OF TERMS &amp; GRIEVANCE REPORTING</h4>
<p>SEALTABS may update or amend this Policy at any time, with or without advance notice. In
the event there are significant changes in the way SEALTABS treats User's personally
identifiable information, SEALTABS will display a notice on the Platform or send Users an
email. SEALTABS's current Policy to all information that SEALTABS has about Users and their
account.</p>
<p>Notwithstanding the above, SEALTABS shall not be required to notify the Users of any changes
made to the privacy policy. Should you have any concern or reject the changes in the privacy
policy you can refuse to accept the amendments and opt for withdrawing your Personal
Information by writing to us.</p>
<p>If you have any complaints or grievances with respect to Website/Mobile Application or
Privacy Policy please write to us at <a href="mailto:info@nissiagency.com"> info@nissiagency.com</a>.</p>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </section>
        <div className="clear"></div>
      </div>
    );
};

export default PrivacyPolicy;