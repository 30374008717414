import React, { Component } from "react";
import TourBanner from "./tourBanner";
import Management from "./management";
import Administration from "./administration";
import Teachers from "./teachers";
import Students from "./students";

class Tour extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="tour">
          <TourBanner />
          <div className="container">
            <div className="tabs__container">
              <input id="tab1" type="radio" name="tabs" defaultChecked />
              <label htmlFor="tab1">For Management</label>
              <input id="tab2" type="radio" name="tabs" />
              <label htmlFor="tab2">For Administration</label>
              <input id="tab3" type="radio" name="tabs" />
              <label htmlFor="tab3">For Teachers</label>
              <input id="tab4" type="radio" name="tabs" />
              <label htmlFor="tab4">For Students</label>
              <div className="tabs__content">
                <div id="content1">
                  <Management />
                </div>

                <div id="content2">
                  <Administration />
                </div>

                <div id="content3">
                  <Teachers />
                </div>

                <div id="content4">
                  <Students />
                </div>
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </section>
      </React.Fragment>
    );
  }
}

export default Tour;
