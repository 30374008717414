import React from "react";
import DataRetrieval from "../../assets/images/dataretrieval.png";

const WhySealtabsDataRetrieval = (props) => {
  return (
    <section className="section section__data">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={DataRetrieval}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Data Analytics</h3>
                <p>
                  A simplified data analytics system enables reports to be
                  customized as per the institution’s requirements. Also
                  provides in depth student,teacher and various other analysis
                  required by the institution.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsDataRetrieval;
