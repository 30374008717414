import React from "react";
import Sujay from "../../assets/images/team/sujay.png";
import Kushal from "../../assets/images/team/kushal.png";
import Sharan from "../../assets/images/team/sharan.png";
import TeamCollage from "../../assets/images/team/teamCollage.png";

const OurTeam = (props) => {
  return (
    <section className="section section__ourTeam">
      <div className="container">
        <h2 className="text-center">Meet Our Team</h2>
        <div className="row team__wrapper">
          <div className="col-xs-12 col-sm-6 col-md-4 team__wrap">
            <div className="card">
              <img src={Sujay} className="card-img-top" alt="Sujay" />
              <div className="card-body">
                  <h5>SUJAY</h5>
                  <p>CEO</p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 team__wrap">
            <div className="card">
              <img src={Kushal} className="card-img-top" alt="Kushal" />
              <div className="card-body">
                  <h5>KUSHAL</h5>
                  <p>CTO</p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 team__wrap">
            <div className="card">
              <img src={Sharan} className="card-img-top" alt="Sharan" />
              <div className="card-body">
                  <h5>SHARAN</h5>
                  <p>COO</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row about__teamCollage">
          <div className="col-xs-12 col-sm-12 col-md-12">
          <img src={TeamCollage} alt={"Nissi TeamCollage"} />
        </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
