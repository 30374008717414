import React from "react";
import { Link } from "react-router-dom";
import Check from "../../assets/images/check.png";
import Cross from "../../assets/images/cross.png";

const Packages = (props) => {
  return (
    <section className="section section__packages">
      <div className="container">
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Features</th>
                <th scope="col">Lite</th>
                <th scope="col">Lite Plus</th>
                <th scope="col">Pro</th>
                <th scope="col">Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Attendance</td>
                <td>Daily</td>
                <td>Hourly</td>
                <td>Hourly</td>
                <td>Hourly</td>
              </tr>
              <tr>
                <td>Marks</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Sms</td>
                <td>15,000</td>
                <td>50,000</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Performance Analysis</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Timetable</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Institution Profile</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Student Certificates</td>
                <td>PDF</td>
                <td>PDF</td>
                <td>PDF</td>
                <td>PDF</td>
              </tr>
              <tr>
                <td>Faculty Database Management</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Question banks</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Home works &amp; Assignments</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Exam Portions &amp; Timetable</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Newsletters &amp; Circulars</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>PDF Marks card</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>On-call support</td>
                <td>
                  <img src={Check} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Admission Management</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>News Events &amp; Gallery</td>
                <td>
                  <img src={Check} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Printed Marks Card</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>2 Per Annum </td>
                <td>4 Per Annum</td>
              </tr>
              <tr>
                <td>Customisation</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>100 Hours</td>
                <td>200 Hours</td>
              </tr>
              <tr>
                <td>Resource</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>Float</td>
                <td>Dedicated </td>
              </tr>
              <tr>
                <td>Emails</td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Lesson Plan</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Fee Management</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Library Management</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Inventory Management</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Transportation Management</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>HR Payroll</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>Visitor Log</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td>SEALTABS LEARNING (Live Classes, Recorded Sessions, Assessments, Doubts, Notes).</td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Cross} alt="No" />
                </td>
                <td>
                  <img src={Check} alt="Yes" />
                </td>
              </tr>
              <tr>
                <td></td>
                <td><Link to="/contactus"><button className="button">Get Trial</button></Link></td>
                <td><Link to="/contactus"><button className="button">Get Trial</button></Link></td>
                <td><Link to="/contactus"><button className="button">Get Trial</button></Link></td>
                <td><Link to="/contactus"><button className="button">Get Trial</button></Link></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default Packages;
