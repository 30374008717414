import React from "react";

const Logo = () => (
  <svg
    className="logo"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 142.656 30.061"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs></defs>
    <g transform="translate(-167.078 -541.408)">
      <g transform="translate(167.078 541.408)">
        <path
          className="logo__path"
          d="M181.215,557.936a7.34,7.34,0,0,0-1.922-1.45,23.217,23.217,0,0,0-3.083-1.362c-.608-.229-1.11-.441-1.5-.633a6.387,6.387,0,0,1-.932-.535,1.28,1.28,0,0,1-.456-.513,1.522,1.522,0,0,1-.115-.61q0-1.484,2.664-1.484a11.712,11.712,0,0,1,2.607.267,20.618,20.618,0,0,1,2.151.608l.989-4.413a16.238,16.238,0,0,0-2.664-.705,18.42,18.42,0,0,0-3.5-.324,9.1,9.1,0,0,0-5.745,1.638,5.349,5.349,0,0,0-2.094,4.452,6.549,6.549,0,0,0,.418,2.473,5.194,5.194,0,0,0,1.18,1.77,7.092,7.092,0,0,0,1.846,1.275,25.542,25.542,0,0,0,2.416,1.028,15.917,15.917,0,0,1,2.55,1.16,1.448,1.448,0,0,1,.837,1.2,1.179,1.179,0,0,1-.648,1.18,5.672,5.672,0,0,1-2.206.3,14.432,14.432,0,0,1-3.006-.324,18.513,18.513,0,0,1-2.969-.894l-.951,4.6a17.821,17.821,0,0,0,2.455.762,18.114,18.114,0,0,0,4.433.456,11.116,11.116,0,0,0,6.3-1.517,5.078,5.078,0,0,0,2.226-4.482,7.662,7.662,0,0,0-.286-2.2A4.474,4.474,0,0,0,181.215,557.936Z"
          transform="translate(-167.078 -537.802)"
        />
        <path
          className="logo__path"
          d="M187.278,546.782a9.567,9.567,0,0,0-3.558.687,8.933,8.933,0,0,0-3.063,2.015,9.985,9.985,0,0,0-2.151,3.33,12.223,12.223,0,0,0-.817,4.662,13.061,13.061,0,0,0,.627,4.109,8.705,8.705,0,0,0,1.942,3.292,9.148,9.148,0,0,0,3.31,2.187,12.6,12.6,0,0,0,4.7.8,20.277,20.277,0,0,0,2.132-.114c.709-.077,1.375-.17,2-.286a15.856,15.856,0,0,0,1.693-.4,7.808,7.808,0,0,0,1.218-.456l-.76-4.605a11.507,11.507,0,0,1-2.455.705,16.507,16.507,0,0,1-3.063.284,6.576,6.576,0,0,1-3.882-1.026,3.768,3.768,0,0,1-1.636-2.779h12.862c.025-.3.05-.652.075-1.046s.038-.754.038-1.084q0-5.061-2.473-7.668A8.85,8.85,0,0,0,187.278,546.782Zm-3.766,8.526a8.026,8.026,0,0,1,.361-1.429,3.841,3.841,0,0,1,.7-1.235,3.374,3.374,0,0,1,1.141-.857,3.813,3.813,0,0,1,1.638-.322,3.606,3.606,0,0,1,1.654.341,3.47,3.47,0,0,1,1.1.876,3.559,3.559,0,0,1,.647,1.218,5.956,5.956,0,0,1,.247,1.409Z"
          transform="translate(-159.957 -537.802)"
        />
        <path
          className="logo__path"
          d="M207.428,566.761v-11.8a12.764,12.764,0,0,0-.418-3.387,6.051,6.051,0,0,0-1.427-2.588,6.515,6.515,0,0,0-2.684-1.636,13.116,13.116,0,0,0-4.186-.572c-.66,0-1.312.033-1.958.1s-1.263.14-1.846.229-1.123.19-1.618.3-.907.222-1.237.324l.724,4.567a12.287,12.287,0,0,1,2.435-.592,19.506,19.506,0,0,1,2.854-.209,4.24,4.24,0,0,1,3.045.876,3.143,3.143,0,0,1,.876,2.321v.533a8.917,8.917,0,0,0-1.389-.247,17.922,17.922,0,0,0-1.96-.1,15.157,15.157,0,0,0-3.158.324,8.508,8.508,0,0,0-2.7,1.046,5.417,5.417,0,0,0-1.883,1.94,5.822,5.822,0,0,0-.7,2.968,7.027,7.027,0,0,0,.608,3.083,5.124,5.124,0,0,0,1.75,2.035,7.633,7.633,0,0,0,2.72,1.123,16.619,16.619,0,0,0,3.521.343,32.343,32.343,0,0,0,5.25-.363Q206.212,567.029,207.428,566.761Zm-5.441-3.539a10.808,10.808,0,0,1-1.218.114c-.508.027-1.041.038-1.6.038a4.937,4.937,0,0,1-2.455-.513,1.944,1.944,0,0,1-.894-1.847,2.006,2.006,0,0,1,.3-1.141,2.126,2.126,0,0,1,.837-.724,3.94,3.94,0,0,1,1.237-.361,11.124,11.124,0,0,1,2.759-.018c.456.05.8.1,1.028.152Z"
          transform="translate(-151.568 -537.802)"
        />
        <path
          className="logo__path"
          d="M203.012,542.321v29.034h5.67V541.408Z"
          transform="translate(-142.966 -541.408)"
        />
        <path
          className="logo__path"
          d="M222.006,555.64v-4.718h-6.812v-9.513l-5.67.914v29.033h5.67V555.64Z"
          transform="translate(-138.596 -541.408)"
        />
        <path
          className="logo__path"
          d="M233.318,548.99a6.5,6.5,0,0,0-2.682-1.636,13.116,13.116,0,0,0-4.186-.572c-.66,0-1.312.033-1.958.1s-1.263.14-1.846.229-1.123.19-1.618.3-.907.222-1.237.324l.724,4.567a12.287,12.287,0,0,1,2.435-.592,19.506,19.506,0,0,1,2.854-.209,4.24,4.24,0,0,1,3.045.876,3.143,3.143,0,0,1,.876,2.321v.533a8.915,8.915,0,0,0-1.389-.247,17.923,17.923,0,0,0-1.96-.1,15.157,15.157,0,0,0-3.158.324,8.508,8.508,0,0,0-2.7,1.046,5.417,5.417,0,0,0-1.883,1.94,5.822,5.822,0,0,0-.7,2.968,7.027,7.027,0,0,0,.608,3.083,5.124,5.124,0,0,0,1.75,2.035,7.633,7.633,0,0,0,2.72,1.123,16.619,16.619,0,0,0,3.521.343,32.344,32.344,0,0,0,5.25-.363q2.171-.358,3.387-.627v-11.8a12.763,12.763,0,0,0-.418-3.387A6.037,6.037,0,0,0,233.318,548.99Zm-3.594,14.232a10.807,10.807,0,0,1-1.218.114c-.508.027-1.041.038-1.6.038a4.937,4.937,0,0,1-2.455-.513,1.944,1.944,0,0,1-.894-1.847,2.006,2.006,0,0,1,.3-1.141,2.126,2.126,0,0,1,.837-.724,3.939,3.939,0,0,1,1.237-.361,11.123,11.123,0,0,1,2.759-.018c.456.05.8.1,1.028.152Z"
          transform="translate(-132.957 -537.802)"
        />
        <path
          className="logo__path"
          d="M247.111,553.357a7.379,7.379,0,0,0-2.74-2.112,9.031,9.031,0,0,0-3.766-.742,10.07,10.07,0,0,0-2.207.229,10.585,10.585,0,0,0-1.978.647v-9.971l-5.67.914V570.29c.506.154,1.091.3,1.75.438s1.352.259,2.075.363,1.457.182,2.206.246,1.465.1,2.151.1a12.251,12.251,0,0,0,4.413-.742,8.957,8.957,0,0,0,3.273-2.11,9.172,9.172,0,0,0,2.035-3.312,12.639,12.639,0,0,0,.7-4.338,14.978,14.978,0,0,0-.57-4.28A9.491,9.491,0,0,0,247.111,553.357ZM242.392,565a4.073,4.073,0,0,1-3.5,1.6c-.508,0-.976-.018-1.407-.057s-.787-.082-1.066-.132V556.1a4.953,4.953,0,0,1,1.389-.57,6.7,6.7,0,0,1,1.731-.229q4.033,0,4.034,5.479A6.943,6.943,0,0,1,242.392,565Z"
          transform="translate(-124.355 -541.408)"
        />
        <path
          className="logo__path"
          d="M258.351,559.662a4.47,4.47,0,0,0-.988-1.726,7.4,7.4,0,0,0-1.922-1.45,23.312,23.312,0,0,0-3.083-1.362c-.608-.229-1.11-.441-1.5-.633a6.269,6.269,0,0,1-.931-.535,1.3,1.3,0,0,1-.458-.513,1.552,1.552,0,0,1-.114-.61q0-1.484,2.664-1.484a11.713,11.713,0,0,1,2.607.267,20.584,20.584,0,0,1,2.149.608l.991-4.413a16.286,16.286,0,0,0-2.664-.705,18.451,18.451,0,0,0-3.5-.324,9.1,9.1,0,0,0-5.745,1.638,5.349,5.349,0,0,0-2.094,4.452,6.548,6.548,0,0,0,.419,2.473,5.147,5.147,0,0,0,1.18,1.77,7.111,7.111,0,0,0,1.845,1.275,25.921,25.921,0,0,0,2.416,1.028,15.917,15.917,0,0,1,2.55,1.16,1.445,1.445,0,0,1,.837,1.2,1.177,1.177,0,0,1-.647,1.18,5.682,5.682,0,0,1-2.207.3,14.432,14.432,0,0,1-3.006-.324,18.546,18.546,0,0,1-2.968-.894l-.951,4.6a17.7,17.7,0,0,0,2.453.762,18.123,18.123,0,0,0,4.433.456,11.113,11.113,0,0,0,6.3-1.517,5.078,5.078,0,0,0,2.226-4.482A7.662,7.662,0,0,0,258.351,559.662Z"
          transform="translate(-115.982 -537.802)"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
