import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from 'react-query'
import Recaptcha from 'react-recaptcha'
import { successToast, errorToast } from '../../helpers/utils'

const ContactDetails = (props) => {
  let recaptchaInstance = useRef(null)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    query: "",
  });
  const [captchaVerified, setVerified] = useState(false)

  //muttation
  const postConatctForm = useMutation(data => fetch("https://te73viez8k.execute-api.ap-south-1.amazonaws.com/production/inquiry", {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }))

  const fieldOnchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    if (captchaVerified) {
      if (formData?.name && formData?.email && formData?.number) {
        const content = "<div><h4>Name : " + formData?.name + "</h4><h4>Email : " + formData?.email + "</h4><h4>Phone : " + formData?.number + "</h4><h4>Message : " + formData?.query + "</h4></div>"
        const data = { "to": "info@nissiagency.com", "text": content, "subject": "Sealtabs-Website - Demo Request", "key": "D&4M!pm4qFsXn1BnQ&K6Y@tUNH2@HI" }
        postConatctForm.mutate(data)
      }
      else {
        errorToast("Your data is safe with us, please enter the required details.")
      }
    }
    else {
      errorToast("Please verify the captcha!")
    }
  };

  useEffect(() => {
    if (postConatctForm?.status === "success") {
      successToast("Received the Demo Request, we will get in touch soon!!")
      setFormData({
        name: "",
        email: "",
        number: "",
        query: "",
      })
      setVerified(false)
      recaptchaInstance.reset()
    }
    else if (postConatctForm?.status === "error") {
      errorToast("Demo Request not sent, please try again after sometime!!")
    }
  }, [postConatctForm?.status])

  return (
    <section className="section section__callForOffers">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6 col-lg-5">
            <div className="contactusform__wrapper">
              <h5>Contact Us</h5>
              <p>Contact us for demo or any other queries.</p>
              <form className="contactus__form" onSubmit={(e) => e.preventDefault()}>
                <input
                  onChange={(e) => fieldOnchange(e)}
                  name="name"
                  value={formData?.name}
                  type="text"
                  placeholder="Full Name"
                />
                <input
                  onChange={(e) => fieldOnchange(e)}
                  name="email"
                  value={formData?.email}
                  type="email"
                  placeholder="Email Id"
                />
                <input
                  onChange={(e) => fieldOnchange(e)}
                  name="number"
                  value={formData?.number}
                  type="tel"
                  placeholder="Phone Number"
                />
                <textarea
                  onChange={(e) => fieldOnchange(e)}
                  name="query"
                  value={formData?.query}
                  placeholder="Query"
                ></textarea>
                <Recaptcha
                  ref={e => recaptchaInstance = e}
                  render="explicit"
                  sitekey="6LeYszsbAAAAAFavsbLLJog9MQfeoj4_SKm4oxfL"
                  verifyCallback={() => { setVerified(true)}}
                />
                {postConatctForm?.status === "loading" ?<button
                  type="submit"
                  onClick={() => onSubmitForm()}
                  className="button"
                >
                  Sending...
                </button>:<button
                  type="submit"
                  onClick={() => onSubmitForm()}
                  className="button"
                >
                  Submit
                </button>}
              </form>
              <div className="clear"></div>
            </div>
          </div>
          <div className="col-md-6 col-lg-7">
            {/* <h5>Sales</h5>
              <p>we’d love to talk about how we can work together</p>
              <p><a className="button button--transperent" href="#top">Contact sales</a></p> */}

            <h5 className="mar--t40">Help &amp; Support</h5>
            <p>we’re here to help with any questions or code.</p>

            {/* <h5 className="mar--t40">General Communication</h5>
              <p>For general queries, please email or call</p> */}
            <p>
              {" "}
              <a href="mailto:info@sealtabs.com">info@sealtabs.com</a>
            </p>
            <p>
              {" "}
              <a href="tel:+91 80 88 100 900">+91 80 88 100 900</a>
            </p>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default ContactDetails;
