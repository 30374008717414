import React from "react";
import { Link } from "react-router-dom";
import Sitemap from "../../assets/images/sitemap.png";

const sitemap = (props) => {
  return (
    <React.Fragment>
      <div className="sitemap">
        <section className="section section__banner d-flex ">
          <div className="container">
            <div className="row justify-content-center align-items-center h-100">
              <div className="col-md-6 col-sm-12">
                <h1>Sitemap</h1>
                <ul className="sitemap__list">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/whysealtabs">Why Sealtabs</Link>
                  </li>
                  <li>
                    <Link to="/features">Features</Link>
                  </li>
                  <li>
                    <Link to="/tour">Tour</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                </ul>
                <ul className="sitemap__list">
                  <li>
                    <a rel="noreferrer" target="_blank" href="https://app.sealtabs.com/login">Login</a>
                  </li>
                  <li>
                    <Link to="/contactus">Ask for demo</Link>
                  </li>
                  <li>
                    <Link to="/contactus">ContactUs</Link>
                  </li>
                  <li>
                    <Link to="/sitemap">Sitemap</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={Sitemap} alt="Sitemap" />
              </div>
            </div>
          </div>
        </section>
        <div className="clear"></div>
      </div>
    </React.Fragment>
  );
};

export default sitemap;
