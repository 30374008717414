import React from "react";
import Instantaccess from "../../assets/images/instantaccess.png";

const WhySealtabsInstantAccess = (props) => {
  return (
    <section className="section section__whySealtabsInstantAccess">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={Instantaccess}
              alt="Instant Access"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Accessible Anytime Anywhere</h3>
                <p>
                  This web-based tool utilises a technologically advanced
                  platform to provide round-the-clock and on-the-go access to
                  any information one might require.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsInstantAccess;
