import React from "react";
import { Link } from "react-router-dom";
import Logo from "../molecules/logo";
import appstore from "../../../assets/images/appstore.png";
import playStore from "../../../assets/images/googleplay.png";
import fb from "../../../assets/images/facebook.png";
import insta from "../../../assets/images/instagram.png";
import linkedin from "../../../assets/images/linkedin.png";
import twitter from "../../../assets/images/twitter.png";
// import youtube from "../../../assets/images/youtube.png";

const Footer = (props) => {
  return (
    <footer>
      <div className="footer">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 footer__col1">
            <Link to="/">
              <Logo />
            </Link>
            <p>
              A Product of <a rel="noreferrer" target="_blank" href="https://nissiagency.com"><span>Nissi Computing Labs</span></a>
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 footer__col2">
            {/* <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/whysealtabs">Why Sealtabs</Link>
              </li>
              <li>
                <Link to="/features">Features</Link>
              </li>
              <li>
                <Link to="/tour">Tour</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <a href="https://app.sealtabs.com/login">login</a>
              </li>
              <li>
                <Link to="/contactus">Ask for demo</Link>
              </li>
              <li>
                <Link to="/contactus">ContactUs</Link>
              </li>
            </ul> */}
            <ul>
              <li>
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              {/* <li>
                <Link to="/sitemap">Sitemap</Link>
              </li> */}
            </ul>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3  footer__col3">
            <p><span className="availableOnLogo"><Logo /></span> is now available on</p>
            <ul>
              <li>
                <a rel="noreferrer" target="_blank" href="https://apps.apple.com/lk/app/sealtabs/id1520189933">
                  <img src={appstore} alt="App Store" />
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.nissiagency.sealtabsv2">
                  <img src={playStore} alt="Play Store" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 footer__col4">
            <p>Follow us on</p>

            <ul>
              <li>
                <a rel="noreferrer" target="_blank" href="https://www.facebook.com/NissiLLP">
                  <img src={fb} alt="Facebook" />
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://www.instagram.com/nissicomputinglabs">
                  <img src={insta} alt="Instagram" />
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/nissi-agency-llp-01237252">
                  <img src={linkedin} alt="Linkedin" />
                </a>
              </li>
              <li>
                <a rel="noreferrer" target="_blank" href="https://twitter.com/NissiLLP">
                  <img src={twitter} alt="Twitter" />
                </a>
              </li>
              {/* <li>
                <a rel="noreferrer" target="_blank" href="#top">
                  <img src={youtube} alt="Youtube" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <p className=" footer__copyright">
          &copy;2020 Nissi Agency LLP. All rights reserved.
        </p>
        <div className="clear"></div>
      </div>
    </footer>
  );
};

export default Footer;
