import React from 'react';

const tourBanner = (props) => {
    return (
        <section className="section section__banner">
        <div className="container">
            <div className="col-md-6 col-sm-12 bannerBg__overlay">
              <h1>Tour</h1>
              <h5>See how SealTabs could work for your school</h5>
            </div>
          </div>
      </section>
    );
}

export default tourBanner;