import React from "react";
import Datamaintenance from "../../assets/images/datamaintenance.png";

const WhySealtabsData = (props) => {
  return (
    <section className="section section__whySealtabsData">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={Datamaintenance}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>High Data Accuracy</h3>
                <p>
                  An easy user interface for quick entry of student details like
                  marks, attendance and fees enables smoother workflow and
                  ultimately helps in better administration of the institute.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsData;
