import React from "react";

const WhySealtabsBanner = props => {
  return (
    <section className="section section__banner">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1>Why Sealtabs</h1>
            <h5>Simple. Efficient. Accountable.</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhySealtabsBanner;
