import React from "react";

const featuresBanner = props => {
  return (
    <section className="section section__banner">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 bannerBg__overlayWhite">
            <h1>Features</h1>
            <h5>Educational Tools<br/>For Improving Efficiency.</h5>
          </div>
        </div>
      </div>
    </section>
  );
};

export default featuresBanner;
