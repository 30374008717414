import React from "react";
import Routing from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/style.css";
import 'react-toastify/dist/ReactToastify.css';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

// Create a client
const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routing />
    </QueryClientProvider>
  );
}
export default App;
