import React from "react";
import TeachersAttendance from "../../assets/images/teachersAttendance.png";
import TeachersMarks from "../../assets/images/teachersMarks.png";
import TeachersAnalysis from "../../assets/images/teachersAnalysis.png";

const teachers = (props) => {
  return (
    <section className="section section__teachers">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={TeachersAttendance}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Attendance tracking
                  <br />
                  &amp; Reports
                </h3>
                <p>
                  Our software keeps track of every student in the database. It
                  is a great way to reduce a teacher’s hassles because of manual
                  entries that wastes their time during class. A teacher can
                  mark attendance in less than a minute and also generate
                  reports if required with just a click of a button.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={TeachersMarks}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Marks based reports
                  <br />
                  &amp; automatic generations
                  <br />
                  of report cards
                </h3>
                <p>
                  We also have an easy to use the user-friendly portal to view
                  Marks based reports and also auto-generation of report cards
                  whenever necessary. You can pull out data for individual
                  students, class, sections and other reports that may be
                  required by the institution.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={TeachersAnalysis}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Performance Analysis
                  <br />
                  according to classes and subjects
                </h3>
                <p>
                  This is one of the main features that most institutions look
                  for and we have designed. A state of the art Performance
                  analysis. This again can extract data for anything from the
                  whole class performance to individual student performance. Our
                  software auto-generates data within seconds for you to review.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default teachers;
