import React from "react";
import AdministrationRecord from "../../assets/images/administrationRecord.png";
import AdministrationCertificates from "../../assets/images/administrationCertificates.png";
import AdministrationDatabase from "../../assets/images/administrationDatabase.png";
import AdministrationFunctions from "../../assets/images/administrationFunctions.png";
import StudentNotification from "../../assets/images/studentNotification.png";

const administration = (props) => {
  return (
    <section className="section section__administration">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={AdministrationRecord}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Students Record
                  <br />
                  Management
                </h3>
                <p>
                  Manually maintaining students’ fees record, attendance, and
                  more can prove to be tedious and are more prone to human
                  errors. The student record management system is designed to
                  manage the daily school activities of recording and
                  maintaining by automating it. Features like attendance
                  tracking, library management, parents portal, etc.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={AdministrationCertificates}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Students certificates <br />
                  &amp; documents
                </h3>
                <p>
                  This system stores student data from certificates to their
                  documentation will be available in a secure automated system
                  and can be accessed without any hassles.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={AdministrationDatabase}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Staff database Management</h3>
                <p>
                  This feature deals with staff database management wherein the
                  system has mountains of secure data stored on staff
                  information. Everything you need to know about can be pulled
                  out through this efficient administrative tool.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={AdministrationFunctions}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Administrative functions <br />
                  &amp; reports
                </h3>
                <p>
                  It accumulates and centralizes large amounts of data of
                  learning and automates routing administrative functionality.
                  This package has education's most flexible and interactive
                  scheduling function, thus meeting the communication and
                  information needs of the entire Institute community in
                  real-time. And, it would be utterly simple for everyone to
                  use.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={StudentNotification}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Effective communication</h3>
                <p>
                  We provide various communication channels to provide a
                  seamless experience with a uniform template design that’s
                  reliable, scalable for user communication. Developing a
                  constant interaction between schools and parents, we regularly
                  use our software to share notifications on the go with; SMS
                  and Email. Our System redundancy and a reliable cloud platform
                  enable us to deliver information uptime.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default administration;
