import React from "react";
import AboutBanner from "./aboutBanner";
import AboutContent from "./aboutContent";
import WhoWeAre from "./whoWeAre";
import OurTeam from "./ourTeam";
import JoinOurTeam from "./joinOurTeam";

const About = () => {
  return (
    <section className="about">
      <AboutBanner />
      <AboutContent />
      <WhoWeAre />
      <OurTeam />
      <JoinOurTeam />
      <div className="clear"></div>
    </section>
  );
};

export default About;
