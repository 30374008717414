import React, { Component } from "react";
import SectionBanner from "./sectionBanner";
import SectionWhySealtabs from "./sectionWhySealtabs";
import SectionAbout from "./sectionAbout";
import SectionFeatures from "./sectionFeatures";
import SectionClients from "./sectionClients";
import Testimonials from "../assets/layouts/testimonials";
import FootContact from "../assets/layouts/footContact";
import Banner1 from "../../assets/images/monitor.png";
import Banner2 from "../../assets/images/banner2.png";
import Rjspu from "../../assets/images/logos/rjspu_logo.png";
import Bcgs from "../../assets/images/logos/bcgs_logo.png";
import Tcis from "../../assets/images/logos/TCISlogo.png";
import Brs from "../../assets/images/logos/brs_logo.png";
import Sjhs from "../../assets/images/logos/sjhs_logo.png";
import Sda from "../../assets/images/logos/sda_logo.png";
import Mathruka from "../../assets/images/logos/mathruka_logo.png";
import Vijaya from "../../assets/images/logos/vijaya_logo.png";
import Rvils from "../../assets/images/logos/RVILS_Logo.jpg";
import CWXVideo from "../../assets/video/CWX_Final.mp4"

class Home extends Component {
  state = {
    banner: [
      {
        id: 1,
        h1: "Systematic Education Assessment Link",
        h5: "Education Management Software",
        span: "Powered by",
        h4: "Artificial Intelligence",
        img: Banner1,
        buttonDisplay: "yes",
        buttonText: "Take a Tour",
        buttonLink: "/tour",
      },
      {
        id: 2,
        h1: "Accessible Web Based Application",
        h5: "Any Time Any Device",
        span: "",
        h4: "",
        img: Banner2,
        buttonDisplay: "yes",
        buttonText: "Why Sealtabs",
        buttonLink: "/whysealtabs",
      },
    ],
    testimonials: [
      {
        id: 1,
        content: "We are satisfied and happy to have discovered SEALTABS as our Institution ERP System. SEALTABS Technical Team has developed a good understanding of our vision, workflows and has found a way to successfully complement our operations with world-class customized technology solutions. We are extremely pleased by the Services provided to us at SDA Spencer School and PU College.",
        name: "Mr. Issac Abraham",
        designation: "Principal",
        school: "Seventh Day Adventist School & PU College",
        star: 5,
        logo: Sda,
      },
      {
        id: 2,
        content: "Definitely the most cost-effective Institution Management System with high-quality deliverables and a fantastic product available today in the market. We have implemented SEALTABS in our college and have been using it to its best capacity.",
        name: "Dr. Suresha M",
        designation: "Principal",
        school: "RJS PU College",
        star: 5,
        logo: Rjspu,
      },
      {
        id: 3,
        content: "It is a friendly platform, We didn't have to reform our school system and process as SEALTABS has adapted to it.",
        name: "Dr. Mrs. Lavanya Mithran",
        designation: "Principal",
        school: "Bishop Cotton Girls’ School",
        star: 5,
        logo: Bcgs,
      },
      {
        id: 4,
        content: "I am very pleased with SEALTABS, the Institution Management Software. The team has excellent technical skills, rapid response, and turnaround time. The Highest work quality. Overall amazing technical development and service team.",
        name: "Mrs. Vanitha",
        designation: "Principal",
        school: "Mathruka Niketan School",
        star: 5,
        logo: Mathruka,
      },
      {
        id: 5,
        content: "Performance management and evaluation process has helped the teachers, the management, and myself to keep a track of all the student progress. Besides the in-depth analysis of student performance for each test and exams, they also provide the day-to-day analysis about their attendance and evaluations during the class.",
        name: "Prof. K Vidya",
        designation: "Principal",
        school: "Vijaya Bifurcated PU College",
        star: 5,
        logo: Vijaya,
      },
      {
        id: 6,
        content: "My profound inclination to mention that ‘SEALTABS’ has been a true partner for our endeavour and avocation. Your continued and valuable support since induction is praiseworthy. Needless to mention that, your proactive patronage and perseverance further provide us with the impetus to be an institution. We look forward to having your continued professional co-operation.",
        name: "Mrs. Shantha Susheela",
        designation: "Principal",
        school: "St. Johns High School",
        star: 5,
        logo: Sjhs,
      },
      {
        id: 7,
        content: "I appreciate the SEALTABS team to have been very capable of understanding my requirements and delivering what I expected. The team has been very supportive and willing to deliver. If in the future I have a project that I can identify, I would sincerely recommend it to you.",
        name: "Dr. Priya Anand",
        designation: "Principal",
        school: "TCIS - Whitefield",
        star: 5,
        logo: Tcis,
      },
      {
        id: 8,
        content: "After an extensive evaluation of more than a dozen software vendors to develop the core framework of our next-generation ERP, we chose SEALTABS for their futuristic vision and flexibility of the solution.",
        name: "Mrs. Shakila",
        designation: "Principal",
        school: "BRS Global School",
        star: 5,
        logo: Brs,
      },
      {
        id: 9,
        content: "I have 100% confidence that the team behind this amazing software can execute a project to the end. Whenever I need something, they are always there, and they will get it done. ",
        name: "Dr. Anjina Reddy",
        designation: "Principal",
        school: "RV Institute of Legal Studies",
        star: 5,
        logo: Rvils,
      },
    ],
    currentSlider: 0,
    currentTestimonial: 0,
  };

  nextSlideHandler = () => {
    let slider = this.state.currentSlider;
    if (slider < this.state.banner.length - 1) {
      slider++;
    } else {
      slider = 0;
    }
    this.setState({ currentSlider: slider });
  };

  prevSlideHandler = () => {
    let slider = this.state.currentSlider;
    if (slider === 0) {
      slider = this.state.banner.length - 1;
    } else {
      slider--;
    }
    this.setState({ currentSlider: slider });
  };

  nextTestiHandler = () => {
    let slider = this.state.currentTestimonial;
    if (slider < this.state.testimonials.length - 1) {
      slider++;
    } else {
      slider = 0;
    }
    this.setState({ currentTestimonial: slider });
  };

  prevTestiHandler = () => {
    let slider = this.state.currentTestimonial;
    if (slider === 0) {
      slider = this.state.testimonials.length - 1;
    } else {
      slider--;
    }
    this.setState({ currentTestimonial: slider });
  };

  carouselHandler = (index) => {
    this.setState({ currentSlider: index });
  };
  testimonialHandler = (slide) => {
    this.setState({ currentTestimonial: slide });
  };

  render() {
    // window.setInterval(() => this.nextSlideHandler(), 4000);
    // window.setInterval(() => this.nextTestiHandler(), 4000);
    return (
      <React.Fragment>
        <div className="home">
          <SectionBanner
            prev={() => this.prevSlideHandler()}
            next={() => this.nextSlideHandler()}
            carouselHandler={(index) => this.carouselHandler(index)}
            banner={this.state.banner}
            currentSlider={this.state.currentSlider}
          />
          <SectionWhySealtabs />
          <SectionAbout video={CWXVideo} />
          <SectionFeatures />
          <SectionClients />
          <Testimonials
            prev={() => this.prevTestiHandler()}
            next={() => this.nextTestiHandler()}
            testimonialHandler={(slide) => this.testimonialHandler(slide)}
            testimonials={this.state.testimonials}
            currentTestimonial={this.state.currentTestimonial}
          />
          <FootContact />
          <div className="clear"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
