import React from "react";
import ContactBanner from '../../assets/images/contactUs-banner.png';

const pricingBanner = props => {
  return (
    <section className="section section__banner d-flex ">
      <div className="container">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-6 col-sm-12">
            <h1>Contact Us</h1>
            <h5>We Want To Hear From You</h5>
          </div>
          <div className="col-md-6 col-sm-12">
              <img src={ContactBanner} alt="Pricing" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default pricingBanner;
