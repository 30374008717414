import React from "react";
import PricingBanner from '../../assets/images/pricing-banner.png';

const pricingBanner = props => {
  return (
    <section className="section section__banner d-flex ">
      <div className="container">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-6 col-sm-12">
              <h1>Trial Packages</h1>
              <h5>Choose the Packages that’s right for your Institution.</h5>
          </div>
          <div className="col-md-6 col-sm-12">
              <img src={PricingBanner} alt="Pricing" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default pricingBanner;
