import React from "react";
import callForOffers from "../../assets/images/callForOffers.png";

const CallUsforOffers = (props) => {
  return (
    <section className="section section__callForOffers">
      <div className="container">
        <div className="callForOffers__wrapper">
          <div className="row">
            <div className="col-md-7">
              <h2>Simple, straight forward pricing</h2>
              <h3>Choose the plan that’s right for your Institution.</h3>
              <h4>Call us for offers</h4>
              <h2 className="blue--heading">
                <a className="blue--heading" href="tel:+91 80 88 100 900">
                  +91 80 88 100 900
                </a>
              </h2>
            </div>
            <div className="col-md-5">
              
            <img width="100%"
                className="whySealtabs__img"
                src={callForOffers}
                alt="Data Maintenance"
              />
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default CallUsforOffers;
