import React from "react";

const Faq = (props) => {
  return (
    <section className="section section__faq">
      <div className="container">
        <h2>FAQ</h2>
        <h3>Answers to the most frequently asked questions</h3>

        <div className="faq__container">
          <div className="accordion">
            <input type="radio" name="accordion" id="cb1" />
            <div className="box">
              <label className="box-title" htmlFor="cb1">
                What technical support do you provide?
              </label>
              <label className="box-close" htmlFor="acc-close"></label>
              <div className="box-content">
                We offer round the clock support from Monday to Saturday. We
                also offer support on Sundays if there are any critical issues.
                We offer our support for school management, teachers and
                parents, for any issue and are available throughout the day.
              </div>
            </div>
            <input type="radio" name="accordion" id="cb2" />
            <div className="box">
              <label className="box-title" htmlFor="cb2">
                What browsers does Sealtabs support?
              </label>
              <label className="box-close" htmlFor="acc-close"></label>
              <div className="box-content">
                We support the latest updated version of chrome, safari and
                other browsers. We are constantly upgrading our software to be
                compatible with all browsers.
              </div>
            </div>
            <input type="radio" name="accordion" id="cb3" />
            <div className="box">
              <label className="box-title" htmlFor="cb3">
                Does SealTabs work on mobile devices?
              </label>
              <label className="box-close" htmlFor="acc-close"></label>
              <div className="box-content">
                We have a mobile application that can be downloaded from the
                Playstore for Android and App Store for iOS.
              </div>
            </div>
            <input type="radio" name="accordion" id="cb4" />
            <div className="box">
              <label className="box-title" htmlFor="cb4">
                Is there a free version?
              </label>
              <label className="box-close" htmlFor="acc-close"></label>
              <div className="box-content">
                No, but we do offer a free demo version which can be used for a
                month where we will train on how to use and test out Sealtabs.
              </div>
            </div>

            <input type="radio" name="accordion" id="acc-close" />
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default Faq;
