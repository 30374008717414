import React from 'react';
import JoinOurTeam from '../../assets/images/joinOurTeam.png';

const joinOurTeam = (props) => {
    return (
        <section className="section section__joinourteam">
      <div className="container joinourteam--container">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-6 col-sm-12">
            <h2>Want to Join the Team?</h2>
            <h4>We always looking for talented peoples, let’s work together</h4>
            <h5>Drop us an email</h5>
            <h2><a className="joinourteam--link" href="mailto:info@sealtabs.com">info@sealtabs.com</a></h2>
          </div>
          <div className="col-md-6 col-sm-12">
            <img src={JoinOurTeam} alt="Join Our Team" />
          </div>
        </div>
      </div>
    </section>
    );
}

export default joinOurTeam;