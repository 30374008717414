import React from "react";
import ManagementReports from "../../assets/images/management-reports.png";
import ManagementPerformance from "../../assets/images/management-performance.png";
import ManagementFee from "../../assets/images/management-fee.png";
import ManagementExpense from "../../assets/images/management-expense.png";

const management = (props) => {
  return (
    <section className="section section__management">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={ManagementReports}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Institution Management
                  <br />
                  &amp; Reports
                </h3>
                <p>
                  This system is designed to automate the management processes
                  of an institution for their day-to-day activities. From
                  student admission to examination management, even general
                  resource management can be done through this.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={ManagementPerformance}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Overall Performance <br />
                  &amp; Analysis
                </h3>
                <p>
                  Management can easily generate reports with overall
                  performance and they can also analyze data between classes and
                  sections. They can make them
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={ManagementFee}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Fee Management <br />
                  &amp; Reports
                </h3>
                <p>
                  Management will be able to generate reports effortlessly and
                  hassle-free. Whether it be fee management or general reports
                  required, can be done with a click of a button.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={ManagementExpense}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Expense Management</h3>
                <p>
                  In any institution of learning that is operating at their full
                  capacity, expenses are inevitable! Our expense management
                  module will keep track of their day to day expenses and
                  monitor each and every data of those included.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default management;
