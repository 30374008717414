import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../molecules/logo";
import MenuIcon from "../../../assets/images/menuIcon.svg";

const Head = () => {
  const [navState, setnavState] = useState(true);

  const onToggleNav = () => {
    setnavState(!navState);
  };
  return (
    <header>
      <nav id="menu" className="navbar navbar-expand-lg fixed-top logo--blue">
        <div className="navbar-brand">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <button
          onClick={() => onToggleNav()}
          className="navbar-toggle"
          type="button"
          aria-expanded="false"
        >
          {(navState ? (<img src={MenuIcon} alt="Menu" />) : (<div className="closeIcon"></div>))}
        </button>

        <div className="navbar__first--level tab__displayNone">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="tel:+918088100900">+91 8088100900</a>
            </li>
            <li className="nav-item">
              <Link to="/contactus">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="clear"></div>
        <div className={(navState ? "collapse" : "") + " navbar-collapse"}>
          <ul className="navbar-nav nav ml-auto navbar__second--level">
            <li className="nav-item">
              <Link to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/about">About</Link>
            </li>
            <li className="nav-item">
              <Link to="/whysealtabs">Why Sealtabs</Link>
            </li>
            <li className="nav-item">
              <Link to="/features">Features</Link>
            </li>
            <li className="nav-item">
              <Link to="/tour">Tour</Link>
            </li>
            <li className="nav-item">
              <Link to="/pricing">Pricing</Link>
            </li>
            <li className="nav-item tab__display">
              <Link to="/contactus">Contact Us</Link>
            </li>
            <li className="nav-item tab__display">
              <a rel="noreferrer" target="_blank" href="https://app.sealtabs.com/login">
                login
              </a>
            </li>
            <li className="nav-item tab__displayNone">
              <a className="button__login" rel="noreferrer" target="_blank" href="https://app.sealtabs.com/login">
                login
              </a>
            </li>
            <li className="nav-item tab__displayNone">
              <Link className="button__askForDemo" to="/contactus">
                Ask for demo
              </Link>
            </li>
          </ul>
        </div>
        <div className="clear"></div>
      </nav>
    </header>
  );
};

export default Head;
