import React from "react";
import PricingBanner from "./pricingBanner";
import Packages from "./packages";
import CallUsforOffers from "./callUsforOffers";
import Faq from "./faq";

const Pricing = (props) => {
  return (
    <div className="pricing">
      <PricingBanner />
      <Packages />
      <CallUsforOffers />
      <Faq />
      <div className="clear"></div>
    </div>
  );
};

export default Pricing;
