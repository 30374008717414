import React from "react";
import TeamNissi from "../../assets/images/teamImg.jpg";

const aboutBanner = (props) => {
  return (
    <React.Fragment>
    <section className="section section__banner">
    </section>
    <section className="section section__groupphoto">
    <div className="container">
      <h1>Our Team</h1>
      <img src={TeamNissi} alt="Team Sealtabs" />
    </div>
  </section>
  </React.Fragment>
  );
};

export default aboutBanner;
