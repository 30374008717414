import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from 'react-query'
import Recaptcha from 'react-recaptcha'
import { successToast, errorToast } from '../../../helpers/utils'

const Foot = props => {
  let recaptchaInstance = useRef(null)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    query: "",
  });
  const [captchaVerified, setVerified] = useState(false)

  //muttation
  const postConatctForm = useMutation(data => fetch("https://te73viez8k.execute-api.ap-south-1.amazonaws.com/production/inquiry", {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }))

  const fieldOnchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    if (captchaVerified) {
      if (formData?.name && formData?.email && formData?.number) {
        const content = "<div><h4>Name : " + formData?.name + "</h4><h4>Email : " + formData?.email + "</h4><h4>Phone : " + formData?.number + "</h4></div>"
        const data = { "to": "info@nissiagency.com", "text": content, "subject": "Sealtabs-Website - Demo Request", "key": "D&4M!pm4qFsXn1BnQ&K6Y@tUNH2@HI" }
        postConatctForm.mutate(data)
      }
      else {
        errorToast("Your data is safe with us, please enter the required details.")
      }
    }
    else {
      errorToast("Please verify the captcha!")
    }
  };

  useEffect(() => {
    if (postConatctForm?.status === "success") {
      successToast("Received the Demo Request, we will get in touch soon!!")
      setFormData({
        name: "",
        email: "",
        number: "",
        query: "",
      })
      setVerified(false)
      recaptchaInstance.reset()
    }
    else if (postConatctForm?.status === "error") {
      errorToast("Demo Request not sent, please try again after sometime!!")
    }
  }, [postConatctForm?.status])

  return (
      <section className="section section__contact">
        <div className="container">
          <div className="row foot__contactWrapper">
            <div className="col-sm-12 col-md-6 col-lg-8 foot__contact--wrap1">
              <h2>Experience &amp; Explore</h2>
              <h4>
                Sealtabs' at your institution <span>free</span>
                <br /> for a month.
              </h4>
              <p>CALL</p>
              <a href="tel:+918088100900">+91 80 88 100 900</a>
              <p>MAIL</p>
              <a href="mailto:info@sealtabs.com">info@sealtabs.com</a>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 foot__contact--wrap2">
              <h4>Or get a live product demo</h4>
            <form onSubmit={(e) => e.preventDefault()}>
              <input type="text" placeholder="Full Name" name="name" onChange={(e) => fieldOnchange(e)} value={formData?.name}/>
                <input type="email" placeholder="Email Id" name="email" onChange={(e) => fieldOnchange(e)} value={formData?.email}/>
                <input type="tel" placeholder="Phone Number" name="number" onChange={(e) => fieldOnchange(e)} value={formData?.number}/>
                <Recaptcha
                  ref={e => recaptchaInstance = e}
                  render="explicit"
                  sitekey="6LeYszsbAAAAAFavsbLLJog9MQfeoj4_SKm4oxfL"
                  verifyCallback={() => { setVerified(true) }}
                />
              <button className="button button--banner" onClick={() => onSubmitForm()}>GET Demo</button>
              </form>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Foot;
