import React from "react";
import Timeavailability from "../../assets/images/timeavailability.png";

const WhySealtabsTime = (props) => {
  return (
    <section className="section section__whySealtabsTime">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={Timeavailability}
              alt="Time Availability"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Efficient Reporting</h3>
                <p>
                  For any institution, good &amp; efficient administration is
                  required as it helps to boost the productivity of the
                  institute. The increase in productivity is only possible when
                  we decrease the time in maintaining the track records and
                  increased accuracy in collating the data.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsTime;
