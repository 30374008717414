import React from "react";

const aboutContent = (props) => {
  return (
    <section className="section section__aboutcontent">
      <div className="container">
        <h2>About Sealtabs</h2>
        <p>
          <b>SEALTABS</b> has been built on platforms that can allow the
          product to be <b>shaped specifically</b> to individual Institution
          requirements. Sealtabs'(Systematic Education Assessment Link) is a
          one-stop technology solution for Education/Vocational institutions
          providing 
          <b> Tailor Made</b> Features that help achieve a high level of
          efficiency in their day-to-day deliverables. Sealtabs has not only
          been extensively tried and tested but also been very well received and
          has been used exhaustively in the greater success of institutions.
        </p>

        <p>
          Sealtabs has been specifically designed and intended to cover the
          in-depth functionality of educational/vocational institutions such as
          schools, colleges, vocational training, etc. Supporting institutions
          to automate several tasks and minimize manual interventions.
        </p>

        <p>
          Sealtabs team works tirelessly and is constantly studying the
          educational sector to bring in the best product for you with frequent
          upgrades! It comprises a variety of modules that significantly assist
          teachers and staff in keeping Institution Documentations, Academic
          History/Certifications and other vital institution data like fee, result,
          salary, unlimited SMS Package, and transport, etc. Sealtabs is a
          complete solution for your institution which comes with several
          modules and features that not only help institutions but also make
          sure all the students and parents are benefited from it.
        </p>

        <p>
          We have also integrated our very own learning management system(LMS)
          called <b>SEALTABS Learning</b>. <b>SEALTABS Learning</b> has several features a few of which
          include Teachers creating their own personalized content making it
          simple for students to visualize each concept, and easier to
          understand and Provides Institutions a great value-added platform that
          is extended to their teachers and students helping everyone to be in
          sync throughout the academic year. CWX helps students keep pace with
          the syllabus and portions covered in the institution. It also helps
          them identify their strengths &amp; areas for improvement with
          interactive tests that help students master each concept.
        </p>
        <p>
          Sealtabs is not just about the modules and features but also about our
          seamless product delivery and support that our team provides. We take
          care of our clients throughout the entire process. We as a team, work
          to give you the finest products and leading software on a digital
          platform. Hence reducing the usage of paper which not only helps us as
          a company but our clients to move in the right direction to become
          more eco-friendly and do our part in decreasing our carbon footprint.
          Since our establishment in 2012, we have partnered with over 35+
          institutions. Sealtabs has been and is making an impact on the
          educational front to better the lives of institutes and students
          alike!
        </p>
      </div>
    </section>
  );
};

export default aboutContent;
