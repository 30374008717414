import React, { Component } from "react";
import FeaturesBanner from "./featuresBanner";
// import FeaturesStudPerformance from "./featuresStudPerformance";
// import FeaturesAdministration from "./featuresAdministration";
// import FeaturesOverallPerformance from "./featuresOverallPerformance";
// import FeaturesLeave from "./featuresLeave";
// import FeaturesCalendar from "./featuresCalendar";
// import FeaturesLibrary from "./featuresLibrary";
// import FeaturesNews from "./featuresNews";
// import FeaturesAnalysis from "./featuresAnalysis";
// import FootContact from "../assets/layouts/footContact";
import FeaturesList from "./features";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
      <div className="features">
        <FeaturesBanner />
        <FeaturesList />
        <div className="clear"></div>
      </div>
      </React.Fragment>
    );
  }
}

export default Features;
