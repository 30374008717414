import React from "react";
import SealtabsLearning from "../../assets/images/sealtabsLearning.png"; 
import SealTabsErp from "../../assets/images/adaptability.jpg"; 

const Features = (props) => {
  return (
    <section className="section section__features">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div>
              <h3>SEALTABS ERP</h3>
              <img src={SealTabsErp} alt="SEALTABS Learning" />
              <h3 className="sub__head">Fee Management</h3>
              <p>SEALTABS incorporates a comprehensive fee module solution to automate and transfer fee processes that drives efficiency with reduced costs. It generates reports on the fees paid and dues and sends reminders to parents to pay the fees on time.</p>
              <h3 className="sub__head">Admission Management</h3>
              <p>SEALTABS developed an efficient web-based user-driven software, which empowers schools/colleges to manage all admission operations by digitally entering student data into ERP, reducing manpower and time. Our online application forms for admissions make it easy and convenient for approaching students to apply to your institution. In addition, class allotments, follow-up reminders, and alerts are sent to students.</p>
              <h3 className="sub__head">Student Performance</h3>
              <h4>More time, Better focus, Higher performance</h4>
              <p>Sealtabs manages and controls the entire data flow of individual students which includes student marks, attendance and basic student details. Once the above details are fed into the tool by the teachers, the report cards and other required reports for the school are automatically generated for each individual which can be printed directly from the app or saved for future reference. This creates a smoother workflow whereby the faculty and staff are able to focus on and get a clearer picture of students; through which a comprehensive strategy can then be formulated on how best to guide them and enrich the learning process.</p>
              <h3 className="sub__head">Administration Activity</h3>
              <h4>Seamless Administration, just a click away</h4>
              <p>Sealtabs has been designed to help tackle the administrative challenges of your school through a simplified &amp; user-friendly accounting structure, easy-to- use data management facilities including fees status which can be customized for each school and round-the-clock access. Apart from these unique features and functions, it empowers administrators to focus on strategic analysis and new ways to add value to the education system, ultimately getting work done faster and efficiently.</p>
              <h3 className="sub__head">Overall Performance</h3>
              <h4>Visualise your way up.</h4>
              <p>Through various easy-to-interpret graphs provided by Sealtabs, schools can identify hidden patterns and keep tabs on their overall performance in various activities such as education, co-curricular activities, sports and more. The inputs gained from this can provide a better understanding of where they stand, on the growth sphere. Based on this, they are accordingly able to put in place a solid development strategy that enables them to efficiently impart quality education and thereby enhance overall performance.</p>
              <h3 className="sub__head">Leave Management System</h3>
              <h4>Sanctioning leave made easier.</h4>
              <p>An advanced, easy-to-use Leave Management System assists the administration department in tracking staff attendance and leave. Reports generated based on leave records helps them to quickly identify those already on leave; before approving or denying leave applied for by staff or teachers. Not only the management, but also the staff have access to the system and can check how many days of leave they are entitled to which enables them to plan their leave easily. This helps create the work-life balance that fits teachers’ needs while improving the school’s functioning.</p>
              <h3 className="sub__head">Calendar Of Events</h3>
              <h4>Organize to stay on schedule.</h4>
              <p>Too busy to organize your day-to-day events? We can help you manage your time through a well-defined calendar interface that effectively schedules and tracks any personal occasion or school event and even maintain the lesson plan for each class. Convenient and fast, these in-built tools will alert you to any upcoming appointments, meetings, or events, making it easy for you to stay up-to-date.</p>
              <h3 className="sub__head">Virtual Library</h3>
              <h4>Accessible anywhere, anytime.</h4>
              <p>We help keep a library of all the lessons taught in the school for each class. The relevant lesson docs can be uploaded and schedules maintained which can be accessed by the students at any given time; and discussion forums set up for topics of interest for better understanding. The convenience of accessing the lessons from their own computers at home enriches the learning process as it also brings them personalized support from peers or other students; and provides access to a wealth of class-specific information that would otherwise be difficult to find offline.</p>
              <h3 className="sub__head">News And Events</h3>
              <h4>Engineered to update you.</h4>
              <p>An ease-of-use database interface enables the Management to communicate daily announcements, school news and events which are sent as alerts to all students and staff. Having this important information close at hand keeps them updated and makes them aware of campus happenings.</p>
              <h3 className="sub__head">Sealtabs Analysis</h3>
              <h4>Empower your students.</h4>
              <p>We give graphical representation and indicators of students based on past records of the student considering various aspects from academic performance, co-curricular activities and even their wellbeing. A valuable asset, they help to track, monitor and analyse the student experience, unearth new insights into those most at-risk and explore ways of enriching the education process while helping students perform better.</p>
            </div>
            <div className="clear"></div>
          </div>

          <div className="col-md-6">
            <div>
              <h3>SEALTABS Learning</h3>
              <img src={SealtabsLearning} alt="SEALTABS Learning" />
              <h3 className="sub__head">Live lectures</h3>
              <h4>
                Your space can be your class.
                <br />
                Study or teach from anywhere.
              </h4>
              <p>
                The interaction between teacher and student is the most
                important feature of the classroom, and we provide a tool that
                connects teachers and students, and helps students clear their
                doubts instantly and efficiently.
              </p>
              <h3 className="sub__head">Pre- Recorded Session</h3>
              <h4>Equipping Your Study Area With All the Materials You Need.</h4>
              <p>Providing students with pre-recorded sessions allows them to review a recorded lecture multiple times and learn the course material at their own pace. The use of the modules also frees up time that can ultimately be used for active learning exercises and detailed application of the material. Students believe that combining pre-recorded modules with exercises and study materials allows them to become more actively engaged in the learning process and develops a better understanding of the course material.</p>
              <h3 className="sub__head">Discussions (Doubts)</h3>
              <h4>Encouraging chat interaction.</h4>
              <p>Chats inhabit a great way to increase interaction with students because the level of commitment is low. Hence, we provide a platform to enhance and encourage teacher to student interaction. Allowing students to engage in active conversation to clear their doubts.</p>
              <h3 className="sub__head">Online assessments</h3>
              <p>SEALTABS provides a secure and robust platform for online examinations. Also providing inventive and exquisite solutions for examinations. With this tool, the administrator has the freedom to create customizable questions. The online assessments can be easily shared with students. In addition to these features, the answer scripts are assessed instantly. Score sheets and reports are generated automatically for each individual.</p>
              <h3 className="sub__head">Quick Notes</h3>
              <h4>Allowing students to take quick notes.</h4>
              <p>Learn on your own terms and at your own speed by creating a process that helps you get things done.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default Features;
