import React from "react";
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'

//components
import { CustomLayout } from "./layouts";
import ScrollToTop from './scrollToTop'
import Home from "../home";
import About from "../aboutUs";
import Whysealtabs from "../whySealtabs";
import Features from "../features";
import Tour from "../tour";
import Pricing from "../pricing";
import ContactUs from "../contactUs";
import Sitemap from "../sitemap";
import NotMatch from "../assets/layouts/404";
import TermsConditions from "../TermsConditions"
import PrivacyPolicy from "../privacyPolicy "

export default function routing() {
  return (
    <Router forceRefresh={true}>
    <ScrollToTop>
      <Switch>
        <CustomLayout exact path="/" name="home">
          <Home />
        </CustomLayout>
        <CustomLayout exact path="/about" name="about">
          <About />
        </CustomLayout>
        <CustomLayout exact path="/whysealtabs" name="whysealtabs">
          <Whysealtabs />
        </CustomLayout>
        <CustomLayout exact path="/features" name="features">
          <Features />
        </CustomLayout>
        <CustomLayout exact path="/tour" name="tour">
          <Tour />
        </CustomLayout>
        <CustomLayout exact path="/pricing" name="pricing">
          <Pricing />
        </CustomLayout>
        <CustomLayout exact path="/contactUs" name="contactUs">
          <ContactUs />
        </CustomLayout>
        <CustomLayout exact path="/privacy-policy" name="privacyPolicy">
          <PrivacyPolicy />
        </CustomLayout>
        <CustomLayout exact path="/terms-and-conditions" name="termsConditions">
          <TermsConditions />
        </CustomLayout>
        <CustomLayout exact path="/sitemap" name="sitemap">
          <Sitemap />
        </CustomLayout>
        <CustomLayout exact path="*" name="404">
          <NotMatch />
        </CustomLayout>
      </Switch>
    </ScrollToTop>
  </Router>
  );
};

