import React from "react";
import VirtualInteraction from "../../assets/images/virtualinteraction.png";

const WhySealtabsVirtual = (props) => {
  return (
    <section className="section section__whySealtabsVirtual">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={VirtualInteraction}
              alt="Instant Access"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Virtual Interaction Solutions</h3>
                <p>
                  Facilitates a healthy environment for constructive discussions
                  between students &amp; teachers as well as parents &amp; teachers. The
                  resulting collaborative engagement ensures a quality education
                  that is engaging, interesting, and empowers students to
                  achieve their potential.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsVirtual;
