import React from "react";
import { Link } from "react-router-dom";
import icon1 from "../../assets/images/whySealtabs1.png";
import icon2 from "../../assets/images/whySealtabs2.png";
import icon3 from "../../assets/images/whySealtabs3.png";
import icon4 from "../../assets/images/whySealtabs4.png";
import icon5 from "../../assets/images/whySealtabs5.png";
import icon6 from "../../assets/images/whySealtabs6.png";

const SectionWhySealtabs = props => {
  return (
    <section className="section section__whySealtabs">
      <div className="container">
        <h4 className="text-center">Why Sealtabs</h4>
        <h3 className="text-center">Simple. Efficient. Accountable.</h3>

        <div className="row icon__wrapper">
          <div className="col-sm-6 col-md-4 text-center icon__wrap">
            <img src={icon1} alt="Time Availability" />
            <p>Efficient Reporting</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center icon__wrap">
            <img src={icon2} alt="Data Maintenance" />
            <p>High Data Accuracy</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center icon__wrap">
            <img src={icon3} alt="Instant Access" />
            <p>Accessible Anytime Anywhere</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center icon__wrap">
            <img src={icon4} alt="Eco Friendly" />
            <p>Seamless Delivery</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center icon__wrap">
            <img src={icon5} alt="Virtual Interaction Solutions" />
            <p>Virtual Interaction Solutions</p>
          </div>
          <div className="col-sm-6 col-md-4 text-center icon__wrap">
            <img src={icon6} alt="Data Retrieval" />
            <p>Data Analytics</p>
          </div>
          <div className="text-center mx-auto">
          <Link to="/whysealtabs"> <button className="button">Read More</button></Link>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </section>
  );
};

export default SectionWhySealtabs;
