import React from "react";
import VirtualInteraction from "../../assets/images/security.png";

const WhySealtabsVirtual = (props) => {
  return (
    <section className="section section__data">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={VirtualInteraction}
              alt="Instant Access"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Security</h3>
                <p>
                  SEALTABS prioritizes the security and privacy of user data.
                  Our dedicated team conducts research on cybersecurity
                  practices securing the product and the user. SEALTABS is
                  tested by cybersecurity professionals periodically to identify
                  the bugs and reconstruct them instantly. Our security
                  practices have achieved the industrial standards and
                  guarantees to secure the data and privacy of every user of our
                  platform.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsVirtual;
