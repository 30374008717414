import React from "react";
import Whoweare from "../../assets/images/aboutIllustration.jpg";

const whoWeAre = (props) => {
  return (
    <section className="section section__whoWeAre">
      <div className="container">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-6 col-sm-12">
            <h2>Who are we?</h2>
            <p>
              Released in 2012, <strong>NISSI COMPUTING LABS</strong> has created groundbreaking
              products that are transforming lives of more than <strong>100,000</strong> students
              studying in our <strong>55+</strong> partner institutions. SEALTABS has pioneered
              as the <strong>E</strong>ducation <strong>R</strong>eformation <strong>P</strong>latform (ERP) in India. Working on
              the Bleeding Edge of Technology, we create Reforming products for
              the education sector that make administration, learning and
              teaching easier with inclusivity and effective outcomes.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <img className="whoWeAre--img" src={Whoweare} alt="Pricing" />
          </div>
          <div className="col-md-12 col-sm-12">
            <p>
              Technology in Education is our passion and our area of expertise.
              Over the years, our teams have thoroughly enjoyed the challenge of
              catering to schools across a wide socio-cultural spectrum. We have
              28+ top modules that account for hours of time-efficiency &amp;
              effectiveness. Each of our modules has been designed using
              contemporary working and learning design principles and undergone
              rigorous testing on and off the field. Our products are user
              specific, result centric, administration-effectiveness &amp;
              efficiency and learner-centric and thus make administration,
              teaching and managing the institution easier and more effective
              for The faculty.
            </p>
            <p>
              By doing so, the product is praised by institutions and parents
              for the value driven modules provided, and the key performance and
              analysis modules.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default whoWeAre;
