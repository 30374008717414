import React from "react";
import EcoFriendly from "../../assets/images/ecofriendly.png";

const WhySealtabsEcoFriendly = (props) => {
  return (
    <section className="section section__whySealtabsEcoFriendly">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="whySealtabs__img"
              src={EcoFriendly}
              alt="Data Maintenance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>Seamless Delivery</h3>
                <p>
                  We here at Sealtabs work round the clock to give you a
                  seamless experience in every area. We not only meet
                  expectations but exceed them!
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default WhySealtabsEcoFriendly;
