import React from "react";
import clientLogo1 from "../../assets/images/clientlogos.png";

const SectionClients = props => {
  return (
    <section className="section section__clients">
      <div className="container">
        <h4 className="text-center">Clientele</h4>
        <h3 className="text-center">People who trust us</h3>
        <div className="client__wrapper">
            <img src={clientLogo1} alt="Client Logos" />

          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </section>
  );
};

export default SectionClients;
