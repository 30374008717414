import React from "react";
import { Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify'

import Header from "../../assets/layouts/head";
import Footer from "../../assets/layouts/footer";

export const CustomLayout = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        return (
          <>
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Header />
            {children}
            <div className="clear"></div>
            <Footer />
          </>
        );
      }}
    />
  );
};
