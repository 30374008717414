import React from "react";
import StudentsAttendence from "../../assets/images/studentsAttendence.png";
import StudentNotification from "../../assets/images/studentNotification.png";
import StudentLearning from "../../assets/images/studentLearning.png";

const students = (props) => {
  return (
    <section className="section section__students">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={StudentsAttendence}
              alt="Attendance & Performance"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Attendance &amp; Performance
                  <br />
                  Tracking
                </h3>
                <p>
                  The progress of a child at school is one of the most important
                  concerns of a parent. They are keen to track attendance,
                  performance results and the different activities that take
                  place at the school regarding their child. Sealtabs does
                  exactly that by providing Attendance tracking and also
                  Semester-wise results with grades received for each subject,
                  no matter where they are
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={StudentNotification}
              alt="Notification & Updates from institutions"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Notification &amp; Updates
                  <br />
                  from institutions
                </h3>
                <p>
                  Sealtabs provides Instant communication. SMS, Emails to
                  students, and parents from the institution about general
                  activities, reports and urgent announcements that need to be
                  communicated.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>

        <div className="row flex-row-reverse">
          <div className="col-md-6">
            <img
              className="tour__img"
              src={StudentLearning}
              alt="Learning materials & outcomes"
            />
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div>
                <h3>
                  Learning materials &amp; <br /> outcomes
                </h3>
                <p>
                  We are always improving and upgrading our software to make
                  sure the students and institutions get the best of experiences
                  when it comes to learning materials. This learning material
                  will be created by faculty weekly and will be shared with the
                  students. Along with required assignments/assessments.
                </p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </section>
  );
};

export default students;
