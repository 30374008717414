import React from "react";
import Iframe from "react-iframe";
import { Link } from "react-router-dom";

const SectionAbout = (props) => {
  return (
    <React.Fragment>
      <section className="section section__about">
        <div className="container">
          <h4>About Sealtabs</h4>
          <h3>Systematic Education Assessment Link</h3>
          <p>
            SEALTABS has been built on platforms that can allow the product
            to be shaped specifically to individual Institution requirements.
            Sealtabs'(Systematic Education Assessment Link) is a one-stop
            technology solution for Education/Vocational institutions providing
            Tailor Made Features that help achieve a high level of efficiency in
            their day-to-day deliverables. Sealtabs has not only been
            extensively tried and tested but also been very well received and
            has been used exhaustively in the greater success of institutions.
          </p>
          <div className="row about__videoWrapper">
            <div className="col-sm-12 col-md-6">
              <Iframe
                className="about__video"
                url="http://www.youtube.com/embed/fiXc4AgGh2c"
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <video
                className="sealtabsLearning__video"
                width="100%"
                height="100%"
                controls
              >
                <source src={props.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>

          <Link to="/about">
            <button className="button">Read more about SealTabs</button>
          </Link>
          <div className="clear"></div>
        </div>
      </section>
      <section className="section section__sealtabsLearning">
        <div className="container">
          <div className="clear"></div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SectionAbout;
