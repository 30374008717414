import React from "react";
import { Link } from "react-router-dom";
import icon7 from "../../assets/images/features/student-Performance.png";
import icon8 from "../../assets/images/features/administration-Activity.png";
import icon9 from "../../assets/images/features/overall-Performance.png";
import icon10 from "../../assets/images/features/leave-Management-System.png";
import icon11 from "../../assets/images/features/calendar-Event.png";
import icon12 from "../../assets/images/features/virtualLibrary.png";
import icon13 from "../../assets/images/features/news-events.png";
import icon14 from "../../assets/images/features/sealtabs-Analysis.png";

const SectionFeatures = props => {
  return (
    <section className="section section__features">
      <div className="container">
        <h4 className="text-center">Features</h4>
        <h3 className="text-center">Educational Tools For Efficiency.</h3>
        <div className="row  features__wrapper">
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon7} alt="Student Performance" />
            <h5>
              Student
              <br />
              Performance
            </h5>
            <p>
              More time, Better focus,
              <br />
              Higher performance
            </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon8} alt="Administration Activity" />
            <h5>
              Administration
              <br />
              Activity
            </h5>
            <p>
              Seamless Administration,
              <br />
              just a click away
            </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon9} alt="Overall Performance" />
            <h5>
              Overall
              <br />
              Performance
            </h5>
            <p>
              Visualize your
              <br />
              way up
            </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon10} alt="Leave Management System" />
            <h5>
              Leave Management
              <br />
              System
            </h5>
            <p>
              Sanctioning leave
              <br />
              made easier
            </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon11} alt="Calendar of Event" />
            <h5>
              Calendar of
              <br />
              Event
            </h5>
            <p>
              Organize to stay
              <br />
              on schedule
            </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon12} alt="Virtual Library" />
            <h5>
              Virtual
              <br />
              Library
            </h5>
            <p>
              Accessible anywhere,
              <br />
              anytime
            </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon13} alt="News &amp; Events" />
            <h5>
              News &amp; <br />
              Events
            </h5>
            <p>
              Engineered to
              <br />
              update you
            </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center features__wrap">
            <img src={icon14} alt="Sealtabs Analysis" />
            <h5>
              Sealtabs
              <br />
              Analysis
            </h5>
            <p>
              Empower your
              <br />
              students
            </p>
          </div>
          <div className="text-center mx-auto">
          <Link to="/features"> <button className="button">Read More</button></Link>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </section>
  );
};

export default SectionFeatures;
