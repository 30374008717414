import React from "react";

const TermsConditions = () => {
  return (
    <div className="sitemap">
      <section className="termsConditions section section__banner d-flex">
        <div className="container">
          <h1>Terms &amp; conditions</h1>
          <p>
            Welcome to the SEALTABS’ terms and conditions. These terms and
            conditions (“Terms and Conditions”), are between SEALTABS and you,
            (hereinafter referred to as “You” or “Your” or “User(s)”). By
            accessing our website <a target="_blank" rel="noreferrer" href="https://www.sealtabs.com"> www.sealtabs.com</a> (“Website”) and/or our app
            ‘SEALTABS’ (“Apps”), Website and Apps together shall be referred to
            as “SEALTABS Platform'', you agree to be bound by the provisions of
            these Terms and Conditions.
          </p>
          <p>
            Please read these Terms and Conditions, along with the Privacy
            Policy and all other rules and policies made available or published
            on SEALTABS Platform as they shall govern your use of the SEALTABS
            Platform. By using or visiting the SEALTABS Platform or any SEALTABS
            software, data feeds, and service provided to you on, from, or
            through the SEALTABS Platform, you signify your agreement to (1)
            these “Terms and Conditions”, (2) SEALTABS’s Privacy Policy and any
            other terms that are updated from time to time. If you do not agree
            to any of these terms, please do not use the SEALTABS Platform.
          </p>
          <h4 className="termsSubhead">A. ABOUT SEALTABS</h4>
          <p>
            The domain name, Website and the Apps are owned, registered and
            operated by Nissi Agency LLP, a private company incorporated under
            the (Indian) Companies Act, 2013, and having its registered office
            at{" "}
            <b>
              42/45 SHALOM LAKE CITY 175 KODICHIKKANAHALLI ROTARYNAGAR BO
              MANAHALLI BANGALORE BANGALORE KA 560076 IN
            </b>
            , (hereinafter referred to as “SEALTABS” or “ us” or “we” or “our”
            or “Company”).
          </p>
          <h4 className="termsSubhead">B. SEALTABS PLATFORM</h4>
          <p>
            These Terms and Conditions apply to all Users of the SEALTABS
            Platform, including admin, teachers, parents who are also
            contributors of User data on the SEALTABS Platform. The SEALTABS
            Platform includes all aspects of the Website and Apps which includes
            but is not limited to products, software and service offered via the
            SEALTABS Platform, such as the SEALTABS online platform, SEALTABS
            APP - Subscription, and any other service or application that
            SEALTABS introduces from time to time. SEALTABS Platform is an
            online platform that supports and facilitates the software that
            organizations use to manage day-to-day business, by the Users of the
            SEALTABS Platform and acts as an intermediary between the admin,
            teacher, parents and the students. Any content uploaded through the
            use of the SEALTABS Platform shall thereafter be referred to as
            “User Content”. You agree and acknowledge that SEALTABS has no
            control over and assumes no responsibility for the User Content and
            by using the SEALTABS Platform, you expressly relieve SEALTABS from
            any and all liability arising from the User Content. The SEALTABS
            Platform may include or contain links to any third-party websites
            that may or may not be owned or controlled by SEALTABS. SEALTABS has
            no control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any third-party websites. In
            addition, SEALTABS will not and cannot censor or edit the content of
            any third-party site. By using the SEALTABS Platform, you expressly
            relieve SEALTABS from any and all liability arising from your use of
            any third-party website or services. However, we recommend that
            before you use any third-party services or website you read and
            understand the terms and conditions and privacy policy of each such
            website that you visit.
          </p>
          <p>
            Subject to these Terms and Conditions, Privacy Policy and all other
            rules and policies made available or published elsewhere, SEALTABS
            hereby grants the you a non-exclusive, non-transferable,
            non-sublicensable, limited license to use the SEALTABS Platform in
            accordance with these Terms and Conditions. You agree and
            acknowledge that SEALTABS shall have the right at any time to change
            or discontinue any aspect or feature of the SEALTABS Platform,
            including, but not limited to, the User content, hours of
            availability and equipment needed for access or use. Further,
            SEALTABS may discontinue disseminating any portion of information or
            category of information may change or eliminate any transmission
            method and may change transmission speeds or other signal
            characteristics. SEALTABS reserves the right to refuse access to the
            SEALTABS Platform, terminate Accounts, remove or edit contents
            without any notice to You.
          </p>
          <h4 className="termsSubhead">C. SEALTABS ACCOUNTS</h4>
          <p>
            In order to access some of the features of the SEALTABS Platform,
            you may have to create your account with SEALTABS. You agree and
            confirm that you will never use another User’s account nor provide
            access to your account to any third-party. When creating your
            account, you confirm that the information so provided is accurate
            and complete. Further, you agree that you are solely responsible for
            the activities that occur on your account, and you shall keep your
            account password secure and not share the same with anyone. You must
            notify SEALTABS immediately of any breach of security or
            unauthorized use of your account. At no point in time will SEALTABS
            be liable for any losses caused by any unauthorized use of your
            account, you shall solely be liable for the losses caused to
            SEALTABS or others due to such unauthorized use, if any.
          </p>
          <p>
            SEALTABS takes no responsibility for any User actions on the
            SEALTABS Platform, and further, the User shall be solely responsible
            for his or her own actions for availing the SEALTABS Platform
            provided herein.
          </p>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </section>
      <div className="clear"></div>
    </div>
  );
};

export default TermsConditions;
